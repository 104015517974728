import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// Helper functions for making API calls
import {
  axiosPostData,
  postData,
} from "../../../Survey/helpers/fetch_services";
import { ThemeUpdateContext } from "../../../App";

import "../../../assets/css/MobileRatingMainStyles.css";
import "../../../assets/css/MobileRatingCustomStyles.css";
import LoadingOverlay from "react-loading-overlay";
import {
  addDirectorMessage,
  removeAnswer,
  setSurveyTheme,
} from "../../../redux/survey/actions";
import SingleQuestion from "./components/SingleQuestion";
import SingleAnswer from "./components/SignleAnswer";
import TextType from "./components/TextType";
import CheckBoxType from "./components/CheckBoxType";
import FileUploadType from "./components/FileUploadType";
import RadioType from "./components/RadioType";
import RatingType from "./components/RatingType";
import SequenceType from "./components/SequenceType";
import EmojiType from "./components/EmojiType";
import StarRatingType from "./components/StarRatingType";
import { useCookies } from "react-cookie";
import EmployeeType from "./components/EmployeeType";
import ClientConnectDirector from "../ClientConnectDirector";
import { getDetails } from "../../../webRatingNew/apiHelpers/frontRating";
import CommentsAndImage from "./components/CommentsAndImage";
import { SyncLoader } from "react-spinners";
import sound2 from "../../../sound/facebook_msnger.mp3";
import NumberType from "./components/NumberType";
import SignatureType from "./components/SignatureType";
import { toast } from "react-toastify";
import moment from "moment";
import InventoryType from "./components/InventoryType";
import "./question.css";
import { useIntl } from "react-intl";

// Main React component for rendering survey questions
function Question(props) {
  const intl = useIntl();
  // Destructuring props to extract relevant data
  const {
    survey,
    answeres,
    directorMessage,
    survey_without_login,
    setSurveyTheme_,
    removeAnswer_,
    addDirectorMessage_,
  } = props;
  // Utilizing Context, Router, and Theme hooks
  const { changeTheme } = useContext(ThemeUpdateContext);
  const history = useHistory();
  const location = useLocation();

  const surveyWithoutLogin =
    location.state !== undefined ? location.state.surveyWithoutLogin : "";
  const visitDate =
    location.state !== undefined ? location.state.visitDate : null;
  const visitTimeIn =
    location.state !== undefined ? location.state.visitTimeIn : null;
  const guest_info =
    location.state !== undefined ? location.state.guest_info : null;

  // State hooks for local state management
  const [loading, setLoading] = useState(true);
  const [empLoading, setEmpLoading] = useState(false);

  // Various states for managing survey questions, types, and navigation
  const [allQuestionsOriginal, setAllQuestionsOriginal] = useState([]);
  const [logo, setLogo] = useState("/images/webRating/serve-first.png");
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [cookies, setCookie] = useCookies(["survey"]);
  const [surveyType, setSurveyType] = useState("");

  // Visit Time Out state
  const [selectedTimeOut, setSelectedTimeOut] = useState(null);

  const [contactDetail, setContactDetail] = useState({
    name: null,
    email: null,
    phone: "",
  });
  const defaultContactErrors = {
    name: { error: false, message: "" },
    email: { error: false, message: "" },
    phone: { error: false, message: "" },
    emailOrPhone: { error: false, message: "" },
    emailValid: { error: false, message: "" },
  };
  const [contactDetailErrors, setContactDetailErrors] =
    useState(defaultContactErrors);

  // Function to update contact details
  const updateContactDetails = (e, key) => {
    let { value } = e.target;
    if (key) {
      if (key === "phone") {
        // Automatically add "+44" as a prefix
        // value = "+44" + value.slice(3);

        // Limit the input to a maximum of 10 digits excluding the "+44" prefix
        value = value.slice(0, 10);
      }
      if (key === "email") {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
          updateContactDetailError("emailValid", true, "");
        } else {
          updateContactDetailError("emailValid", false, "");
        }
      }
      setContactDetail((prev) => ({ ...prev, [key]: value }));
    }
  };

  const [customValues, setCustomValues] = useState([]);
  const updateCustomValues = (value, item) => {
    setCustomValues((prev) => ({ ...prev, [item.fieldKey]: value }));
    // remove from the customValuesErrors
    setCustomValuesErrors((prev) => ({
      ...prev,
      [item.fieldKey]: { error: false, message: "" },
    }));
  };

  // Function to update contact detail errors
  const updateContactDetailError = (key, value, message = "") => {
    if (key) {
      setContactDetailErrors((prev) => ({
        ...prev,
        [key]: { error: value, message },
      }));
    }
  };

  // Function to set brand details based on survey ID
  const setBrandDetails = async (survey_id) => {
    const result = await postData("api/getBrandDetail", {
      survey_id,
    });
    if (result?.status === 200) {
      // Set logo if available, otherwise use default logo
      setLogo(
        result?.data?.logo
          ? result?.data?.logo
          : "/images/webRating/serve-first.png"
      );
      localStorage.setItem("logo", result?.data?.logo);
      // Set survey theme based on retrieved data
      setSurveyTheme_({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
      changeTheme({
        primaryColor:
          result?.data?.primaryColor !== "undefined"
            ? result?.data?.primaryColor
            : "#36473F",
        secondaryColor:
          result?.data?.secondaryColor !== "undefined"
            ? result?.data?.secondaryColor
            : "#8FA280",
        backgroundColor:
          result?.data?.backgroundColor !== "undefined"
            ? result?.data?.backgroundColor
            : "#36473F",
        highlightColor:
          result?.data?.highightColor !== "undefined"
            ? result?.data?.highightColor
            : "#8FA280",
        fontColor:
          result?.data?.fontColor !== "undefined"
            ? result?.data?.fontColor
            : "#3a3a3a",
      });
    }
  };

  useEffect(async () => {
    const location = localStorage.getItem("location");
    const logoLocal = localStorage.getItem("logo");
    if (logoLocal) {
      setLogo(logoLocal);
    }
    if (location) {
      setEmpLoading(true);
      const detail = await getDetails(location, "forEmp");
      if (detail) {
        localStorage.setItem("employees", JSON.stringify(detail?.employee));
      }
      setEmpLoading(false);
    }
  }, []);

  // Effect hook to handle survey data changes
  useEffect(() => {
    if (Object?.keys(survey_without_login)?.length) {
      changeTheme({
        primaryColor: props.survey_theme.primaryColor,
        secondaryColor: props.survey_theme.secondaryColor,
      });

      const locationId = localStorage.getItem("location");
      const surveyId = localStorage.getItem("survey");

      //Set Logo and Brand detail
      setBrandDetails(surveyId);

      const results = JSON.parse(JSON.stringify(survey_without_login));
      if (!locationId) {
        results.questions = results?.questions?.filter(
          (item) =>
            item.questionType !== "employees" &&
            item.questionType !== "inventory"
        );
      }

      setCurrentSurvey(results);
      setSurveyType(results.surveyType);
      setAllQuestionsOriginal(survey_without_login?.questions);
      if (
        results?.leadershipUser ||
        results?.visit_information ||
        results?.competitionInformation
      ) {
        setAllQuestions([
          ...results.questions,
          { questionType: "director_message" },
        ]);
      } else {
        setAllQuestions(results.questions);
      }

      setLoading(false);
    }
  }, [survey_without_login]);

  // State to manage question history and answers
  const [questionHistory, setQuestionHistory] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questionLoading, setQuestionLoading] = useState(false);

  // Function to handle question history
  const handleQuestionHistory = async (message, comment = "") => {
    setAnswers([
      ...answers,
      {
        question: allQuestions[currentQuestionIndex],
        answer: message,
        comment: comment,
      },
    ]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setQuestionLoading(true);
    if (currentQuestionIndex !== allQuestions?.length - 1) {
      const audio1 = new Audio(sound2);
      audio1.play();
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setQuestionLoading(false);
  };

  useEffect(() => {
    answers?.forEach((answer) => {
      const questionText = answer?.question?.text;
      const answerText = `${
        answer?.question?.questionType === "inventory"
          ? answer?.answer?.length
            ? answer?.answer
                ?.map((ans) => `${ans?.product}: ${ans?.value}`)
                .join(", ")
            : ""
          : Array.isArray(answer?.answer)
          ? answer?.answer.join(", ") || ""
          : answer?.answer
      }`;

      const questionExist = questionHistory?.some(
        (q) => q?._id === answer?.question?._id
      );
      if (!questionExist) {
        setQuestionHistory((prevHistory) => [
          ...prevHistory,
          {
            text: questionText,
            fromUser: false,
            _id: answer?.question?._id,
            question: answer?.question,
          },
          {
            text: answerText,
            fromUser: true,
            type: answer?.question?.questionType,
            question: answer?.question,
            comment: answer?.comment,
          },
        ]);
      }
    });
  }, [answers]);

  const routeToIndex = (indexToSkip, questionID) => {
    // Initialize variable to store the current question index
    let currentQuestionIndex = "";
    // Loop through all questions to find the index of the question with the specified ID
    for (let index = 0; index < allQuestions.length; index++) {
      const element = allQuestions[index]._id === questionID;
      if (element) {
        currentQuestionIndex = index;
      }
    }
    if (currentQuestionIndex === allQuestions.length - 1) return;

    if (
      currentQuestionIndex >= 0 &&
      currentQuestionIndex <= indexToSkip &&
      indexToSkip < allQuestionsOriginal.length
    ) {
      // Create a new array of questions by slicing the original questions array
      let newData = [
        ...allQuestionsOriginal.slice(0, currentQuestionIndex + 1),
        ...allQuestionsOriginal.slice(indexToSkip),
      ];
      // Check if location is available, and filter out employee-related questions if not
      const locationId = localStorage.getItem("location");
      if (!locationId) {
        newData = newData.filter(
          (item) =>
            item.questionType !== "employees" &&
            item.questionType !== "inventory"
        );
      }
      // Add director message question if the survey involves a leadership user
      if (
        survey_without_login?.leadershipUser ||
        survey_without_login?.visit_information ||
        survey_without_login?.competitionInformation
      ) {
        setAllQuestions([...newData, { questionType: "director_message" }]);
      } else {
        setAllQuestions([...newData]);
      }
    }
  };

  const hideQuestions = async (indexToHide) => {
    const locationId = localStorage.getItem("location");
    const answeresCopy = await answeres;
    const hideQues = [];
    for (let index = 0; index < allQuestions.length; index++) {
      if (allQuestions[index]._id in answeresCopy) {
        let prevAnswer = answeresCopy[allQuestions[index]._id];
        if (
          ["radio", "rating", "emoji"]?.includes(
            allQuestions[index]?.questionType
          ) &&
          prevAnswer.value
        ) {
          const ans = prevAnswer.value;
          const selectedOption = allQuestions[index].options?.find(
            (opt) => opt?._id === ans
          );
          if (selectedOption) {
            hideQues?.push(...selectedOption?.hide_questions);
          }
        }

        if (
          ["multiselect", "sequence"]?.includes(
            allQuestions[index]?.questionType
          ) &&
          prevAnswer?.value
        ) {
          const ans = prevAnswer?.value;

          const selectedOption = allQuestions[index].options?.filter((opt) =>
            ans?.includes(opt?._id)
          );

          if (selectedOption?.length) {
            hideQues?.push(
              ...selectedOption.flatMap((opt) => opt.hide_questions)
            );
          }
        }
      }
    }
    const allHiddenIndex = [...hideQues, ...indexToHide];
    // Filter out questions to be hidden based on their indices
    let filteredQuestions = allQuestionsOriginal?.filter(
      (_, i) => !allHiddenIndex?.includes(i)
    );

    // Remove answers for the hidden questions
    let hiddenQuestions = allQuestionsOriginal?.filter((que, i) =>
      indexToHide?.includes(i)
    );
    hiddenQuestions?.forEach((hiddenQue) =>
      removeAnswer_({ id: hiddenQue?._id })
    );

    // If no location ID is available, filter out questions related to employees
    if (!locationId) {
      filteredQuestions = filteredQuestions.filter(
        (item) =>
          item.questionType !== "employees" && item.questionType !== "inventory"
      );
    }

    // Update the state with the filtered questions
    setAllQuestions(
      survey_without_login?.leadershipUser ||
        survey_without_login?.visit_information ||
        survey_without_login?.competitionInformation
        ? [...filteredQuestions, { questionType: "director_message" }]
        : [...filteredQuestions]
    );
  };

  const [customValuesErrors, setCustomValuesErrors] = useState({});

  const submitAnswers = async () => {
    // Consolidating response data from the contact details
    let responseData = {
      customer_name: survey?.contactDetails.name,
      customer_email: survey?.contactDetails.email,
      customer_phone: survey?.contactDetails.phone,
    };
    const customFields = survey_without_login?.customValues;
    if (
      survey_without_login?.competitionInformation &&
      customFields?.fields?.length > 0
    ) {
      let hasErrors = false;
      // Validate custom values
      customFields?.fields?.forEach((item) => {
        if (item.isRequired && !customValues[item.fieldKey]) {
          setCustomValuesErrors((prev) => ({
            ...prev,
            [item.fieldKey]: {
              error: true,
              message: `${intl.formatMessage({
                id: "please-enter",
              })} ${item.fieldName.toLowerCase()}`,
            },
          }));
          hasErrors = true;
        } else if (
          item.fieldType === "email" &&
          customValues[item.fieldKey] &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
            customValues[item.fieldKey]
          )
        ) {
          setCustomValuesErrors((prev) => ({
            ...prev,
            [item.fieldKey]: {
              error: true,
              message: intl.formatMessage({
                id: "invalid-email-address",
              }),
            },
          }));
          hasErrors = true;
        } else if (
          item.fieldType === "number" &&
          customValues[item.fieldKey] &&
          !/^\d+$/.test(customValues[item.fieldKey])
        ) {
          setCustomValuesErrors((prev) => ({
            ...prev,
            [item.fieldKey]: {
              error: true,
              message: `${intl.formatMessage({
                id: "please-enter-only-digits-for",
              })} ${item.fieldName.toLowerCase()}`,
            },
          }));
          hasErrors = true;
        } else if (item.fieldType === "phone" && customValues[item.fieldKey]) {
          const UK_PHONE_PATTERN = /^\d{10}$/;
          if (!UK_PHONE_PATTERN.test(customValues[item.fieldKey])) {
            setCustomValuesErrors((prev) => ({
              ...prev,
              [item.fieldKey]: {
                error: true,
                message: intl.formatMessage({
                  id: "invalid-phone-number-format",
                }),
              },
            }));
            hasErrors = true;
          }
        }
      });

      if (hasErrors) {
        return;
      }
    }

    const formattedData = Object.entries(customValues).map(([key, value]) => ({
      fieldKey: key,
      value: value,
    }));

    if (currentSurvey?.visit_information && selectedTimeOut === null) {
      toast.error(intl.formatMessage({ id: "please-select-time" }));
      return;
    }
    setLoading(true); // Sets loading state to true at the start of the function
    let filesArray = [];
    let isAllowGoogleQR = false;
    // Destructuring survey data including answers and contact details
    const answeresCopy = await { ...answeres };
    // Loop through answers to find and process file type questions
    for (let item in answeresCopy) {
      const question = allQuestions.find((question) => question._id === item);
      if (question !== undefined) {
        if ("isFile" in answeresCopy[item]) {
          // Determine the question type for file-based questions
          answeresCopy[item].questionType =
            question?.questionType === "handwrittenInput"
              ? "base64"
              : question?.questionType;
          // Push file data to the filesArray
          filesArray.push({ [item]: answeresCopy[item] });
        }
        // Format phone numbers if necessary
        if (
          question?.questionType === "text" &&
          question?.textType === "phone" &&
          answeresCopy[item].value !== ""
        ) {
          answeresCopy[
            item
          ].value = `+${answeresCopy[item].code}${answeresCopy[item].value}`;
          delete answeresCopy[item].code;
        }
        // Remove empty or null values from the answersCopy object
        if (
          !answeresCopy[item]?.value ||
          (Array.isArray(answeresCopy[item].value) &&
            answeresCopy[item].value.length === 0)
        ) {
          delete answeresCopy[item];
        }
        if (
          answeresCopy[item]?.value &&
          question?.options?.find(
            (opt) =>
              opt?._id?.toString() === answeresCopy[item].value?.toString()
          )?.allow_google_review
        ) {
          isAllowGoogleQR = true;
        }
      }
    }
    // Check if there are no answers provided
    if (!Object.keys(answeresCopy)?.length) {
      setLoading(false);
      toast.error(
        intl.formatMessage({
          id: "you-must-provide-at-least-one-answer-before-submitting-the-survey",
        })
      );
      return;
    }

    // Calculate the total score of the survey
    let surveyTotal = 0;
    for (let index = 0; index < allQuestions.length; index++) {
      const question = allQuestions[index];
      surveyTotal += question?.maxScore ? question?.maxScore : 0;
    }

    // Preparing metadata for the survey response
    const metaData = {
      survey_id: localStorage.getItem("survey") || "",
      location_id: localStorage.getItem("location") || "",
      response_user_id: localStorage.getItem("user_id") || "",
      tag_id: localStorage.getItem("tag_id")
        ? localStorage.getItem("tag_id")
        : localStorage.getItem("tag"),
    };

    if (survey_without_login?.visit_information) {
      metaData.visitDate = visitDate;
      metaData.visitTimeIn = visitTimeIn;
      metaData.visitTimeOut = selectedTimeOut
        ? moment(selectedTimeOut).format("HH:mm")
        : null;
    }
    if (survey_without_login?.guest_info) {
      metaData.guest_info = guest_info;
    }

    // Handling audit and email data if available
    const audit = localStorage.getItem("audit") ?? null;
    const email = localStorage.getItem("email") ?? null;
    if (audit) {
      metaData.audit = audit;
      metaData.email = email;
    }
    // Add Login user detail
    const userEmail = localStorage.getItem("user_email") ?? null;
    if (userEmail) {
      metaData.email = userEmail;
    }
    // Add creator_id if available in localstorage
    const ticketCreatorId = localStorage.getItem("creator_id") ?? null;

    // Add call center flag if available in localstorage
    const callcenter = localStorage.getItem("callcenter") ?? null;
    if (callcenter === "true") {
      responseData.call_center = true;
    }

    // Add source if available in localstorage
    const source = localStorage.getItem("source") ?? null;

    // Add custom values to the metadata
    if (formattedData?.length > 0) {
      metaData.customValues = formattedData;
    }

    // Creating a FormData object for sending the survey response
    const form_data = new FormData();
    form_data.append("metaData", JSON.stringify(metaData));
    form_data.append("jsonData", JSON.stringify(answeresCopy));
    form_data.append("responseData", JSON.stringify(responseData));
    form_data.append("director_message", directorMessage);
    form_data.append("ticketCreatorId", ticketCreatorId);
    form_data.append("surveyTotal", surveyTotal);
    form_data.append("source", source);

    // Add company id to the FormaData
    const companyId = localStorage.getItem("company_id");
    form_data.append("company_id", companyId);

    // Adding files to the FormData if any exist
    if (filesArray.length) {
      filesArray.forEach((item) => {
        const key = Object.keys(item)[0];
        const { questionType } = item[key];
        let images = [];

        // Processing image files based on question type
        if (questionType === "file" || questionType === "handwrittenInput") {
          if (item[key]?.value?.length) {
            item[key].value.forEach((img) => images.push(img));
          }
          if (item[key]?.image?.length) {
            item[key].image.forEach((img) => images.push(img));
          }
        } else {
          images = item[key]?.image || [];
        }
        // Appending images to formData
        if (images.length) {
          images.forEach((image) => {
            form_data.append(key, image);
          });
        }
      });
    }

    try {
      /**
       *  DONOT DELETE
       *  This code is to add validation to allow one survey per browser
       */
      // const surveyCookie = cookies.survey;
      // if (surveyCookie?.survey_id !== localStorage.getItem("survey")) {
      // Making an API call to submit the survey answers
      const result = await axiosPostData("survey/answerSurvey", form_data);
      // On successful submission, redirect to thank you page
      if (result) {
        setCookie(
          "survey",
          {
            survey: "submited",
            survey_id: localStorage.getItem("survey") || "",
          },
          { path: "/", maxAge: 86400 }
        );
        if (surveyWithoutLogin) {
          localStorage.removeItem("location");
          history.push({
            pathname: "/surveyThankYou",
            state: {
              surveyWithoutLogin: true,
              uniqueshortcode: result?.data?.results?.uniqueshortcode || "",
              isAllowGoogleQR: isAllowGoogleQR,
            },
          });
        } else {
          history.push({
            pathname: "/surveyThankYou",
            state: {
              uniqueshortcode: result?.data?.results?.uniqueshortcode || "",
              isAllowGoogleQR: isAllowGoogleQR,
            },
          });
        }
      }
      /**
       *  DONOT DELETE
       *  This code is to add validation to allow one survey per browser
       */
      // } else {
      //   history.push("/sameDaySurvey");
      // }
    } catch (e) {
      // Handling errors during submission
      console.log(e);
    }
    setLoading(false); // Sets loading state to false after function execution
  };

  const [submitSurvey, setSubmitSurvey] = useState(false);

  useEffect(() => {
    if (submitSurvey) {
      submitAnswers();
    }
  }, [submitSurvey]);

  const finishSurvey = (shouldFinish, question) => {
    if (!shouldFinish) {
      return;
    }
    let currentQuestionIndex = "";
    // Finding the index of the current question in the array of all questions
    for (let index = 0; index < allQuestions.length; index++) {
      const element = allQuestions[index]._id === question._id;
      if (element) {
        currentQuestionIndex = index;
      }
    }
    for (
      let index = currentQuestionIndex + 1;
      index < allQuestions.length;
      index++
    ) {
      const questionId = allQuestions[index]._id;
      removeAnswer_({ id: questionId });
    }
    // Set submitSurvey state to true to indicate survey completion
    setSubmitSurvey(true);
  };
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight + 100;
    }
  };
  const [contentCallBack, setContentCallBack] = useState(false);

  useEffect(() => {
    scrollToBottom();
    return () => {
      setContentCallBack(false);
    };
  }, [questionHistory, contentCallBack, questionLoading]);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      className="overflow-hidden"
      styles={{
        content: { height: "100vh", display: "flex", width: "100vw" },
      }}
    >
      {/* Render content only when loading state is false */}
      {!loading ? (
        <div className="mobile-view-rating-app min-h-screen font-sans antialiased text-black bg-gray-100 selection:text-white selection:bg-purple-600">
          <div className="relative max-w-lg min-h-screen mx-auto border-l border-r border-gray-200 questionContainer">
            <div className="sticky top-0 left-0 z-10 w-full px-4 py-3 text-center bg-white border-b border-gray-100 shadow">
              <img
                className="w-auto h-8 mx-auto"
                src={logo}
                alt="first-serve-img"
              />
            </div>
            <div
              className="space-y-5 whole-page questionview"
              style={{
                padding: "0.6rem",
                overflowY: "auto",
              }}
              ref={chatContainerRef}
            >
              {/* Render previous answers or questions */}
              {allQuestions[currentQuestionIndex]?.questionType !==
                "director_message" && questionHistory?.length
                ? questionHistory?.map((chat, index) =>
                    chat?.fromUser ? (
                      <SingleAnswer answer={chat} key={index} />
                    ) : (
                      <SingleQuestion
                        survey_theme={survey?.survey_theme}
                        question={chat?.question}
                        key={index}
                      />
                    )
                  )
                : ""}
              {/* Render submit button */}
              {currentQuestionIndex >= allQuestions?.length ? (
                <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2 answerContainer">
                  <div className="px-2 bg-white border-t border-gray-200">
                    <div className="flex items-center justify-end space-x-3 h-18">
                      <button
                        className="btn icon-btn icon-right"
                        title={intl.formatMessage({ id: "submit" })}
                        style={{
                          backgroundColor: survey?.survey_theme?.primaryColor,
                          border: "1px solid",
                          color: "#fff",
                          borderRadius: "1rem",
                        }}
                        disabled={loading}
                        onClick={submitAnswers}
                      >
                        {intl.formatMessage({ id: "submit" })}
                      </button>
                    </div>
                  </div>
                </div>
              ) : allQuestions[currentQuestionIndex]?.questionType ===
                "director_message" ? (
                // Render director message input section
                <>
                  <ClientConnectDirector
                    clientConnect={currentSurvey.clientConnect}
                    leadershipUser={currentSurvey.leadershipUser}
                    value={directorMessage}
                    onChange={(e) => addDirectorMessage_(e.target.value)}
                    isVisitInformation={currentSurvey?.visit_information}
                    selectedTimeOut={selectedTimeOut}
                    setSelectedTimeOut={(time) => {
                      setSelectedTimeOut(time);
                      // setVisitTimeError(false);
                    }}
                    isSurveyCompetition={currentSurvey?.competitionInformation}
                    contactDetail={contactDetail}
                    contactDetailErrors={contactDetailErrors}
                    updateContactDetails={updateContactDetails}
                    competitionInfoText={
                      currentSurvey?.competition_information_text || ""
                    }
                    customValues={currentSurvey?.customValues}
                    updateCustomValues={updateCustomValues}
                    customValuesErrors={customValuesErrors}
                  />
                  <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2 answerContainer">
                    <div className="px-2 bg-white border-t border-gray-200">
                      <div className="flex items-center justify-end space-x-3 h-18">
                        <button
                          className="btn icon-btn icon-right"
                          title={intl.formatMessage({ id: "submit" })}
                          style={{
                            backgroundColor: survey?.survey_theme?.primaryColor,
                            border: "1px solid",
                            color: "#fff",
                            borderRadius: "1rem",
                          }}
                          disabled={loading}
                          onClick={submitAnswers}
                        >
                          {intl.formatMessage({ id: "submit" })}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                !questionLoading && (
                  // Render current question and related components
                  <>
                    <SingleQuestion
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                    />
                    {/* Render comment and image upload section if applicable */}
                    {allQuestions[currentQuestionIndex]?.allow_comment ||
                    allQuestions[currentQuestionIndex]?.allow_file_upload ? (
                      <CommentsAndImage
                        question={allQuestions[currentQuestionIndex]}
                        onContentCallBack={setContentCallBack}
                      />
                    ) : null}
                  </>
                )
              )}

              {!questionLoading &&
              allQuestions[currentQuestionIndex]?.questionType ===
                "handwrittenInput" ? (
                <SignatureType
                  survey_theme={survey?.survey_theme}
                  question={allQuestions[currentQuestionIndex]}
                  onSend={handleQuestionHistory}
                />
              ) : (
                ""
              )}
              {/* Render specific question types based on the question */}
              {!questionLoading ? (
                <div className="fixed bottom-0 z-10 w-full max-w-lg mx-auto -translate-x-1/2 left-1/2 answerContainer">
                  {allQuestions[currentQuestionIndex]?.questionType ===
                  "text" ? (
                    <TextType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                      allQuestions={allQuestions}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "radio" ? (
                    <RadioType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      finishSurvey={finishSurvey}
                      onSend={handleQuestionHistory}
                      headTonext={(skipIndex, questionId) =>
                        routeToIndex(skipIndex, questionId)
                      }
                      hideQuestion={(hideIndex) => hideQuestions(hideIndex)}
                      allQuestionsOriginal={allQuestionsOriginal}
                      setAllQuestions={setAllQuestions}
                      surveyType={surveyType}
                      currentQuestionIndex={currentQuestionIndex}
                      allQuestions={allQuestions}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "employees" ? (
                    <EmployeeType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                      loading={empLoading}
                    />
                  ) : ["multiselect", "skills"].includes(
                      allQuestions[currentQuestionIndex]?.questionType
                    ) ? (
                    <CheckBoxType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                      allQuestionsOriginal={allQuestionsOriginal}
                      setAllQuestions={setAllQuestions}
                      surveyType={surveyType}
                      currentQuestionIndex={currentQuestionIndex}
                      hideQuestion={(hideIndex) => hideQuestions(hideIndex)}
                      finishSurvey={finishSurvey}
                      allQuestions={allQuestions}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "rating" ? (
                    <RatingType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      finishSurvey={finishSurvey}
                      onSend={handleQuestionHistory}
                      headTonext={(skipIndex, questionId) =>
                        routeToIndex(skipIndex, questionId)
                      }
                      hideQuestion={(hideIndex) => hideQuestions(hideIndex)}
                      allQuestionsOriginal={allQuestionsOriginal}
                      setAllQuestions={setAllQuestions}
                      surveyType={surveyType}
                      currentQuestionIndex={currentQuestionIndex}
                      allQuestions={allQuestions}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "sequence" ? (
                    <>
                      <SequenceType
                        survey_theme={survey?.survey_theme}
                        question={allQuestions[currentQuestionIndex]}
                        onSend={handleQuestionHistory}
                        allQuestionsOriginal={allQuestionsOriginal}
                        setAllQuestions={setAllQuestions}
                        surveyType={surveyType}
                        currentQuestionIndex={currentQuestionIndex}
                        hideQuestion={(hideIndex) => hideQuestions(hideIndex)}
                        finishSurvey={finishSurvey}
                        allQuestions={allQuestions}
                      />
                    </>
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "emoji" ? (
                    <EmojiType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                      headTonext={(skipIndex, questionId) =>
                        routeToIndex(skipIndex, questionId)
                      }
                      hideQuestion={(hideIndex) => hideQuestions(hideIndex)}
                      allQuestionsOriginal={allQuestionsOriginal}
                      setAllQuestions={setAllQuestions}
                      surveyType={surveyType}
                      currentQuestionIndex={currentQuestionIndex}
                      allQuestions={allQuestions}
                      finishSurvey={finishSurvey}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "file" ? (
                    <FileUploadType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "star_rating" ? (
                    <StarRatingType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "number" ? (
                    <NumberType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                    />
                  ) : allQuestions[currentQuestionIndex]?.questionType ===
                    "inventory" ? (
                    <InventoryType
                      survey_theme={survey?.survey_theme}
                      question={allQuestions[currentQuestionIndex]}
                      onSend={handleQuestionHistory}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
            {/* Render loading spinner if questions are loading */}
            {questionLoading &&
            allQuestions[currentQuestionIndex]?.questionType !==
              "director_message" ? (
              <div
                style={{
                  bottom: "1%",
                  position: "absolute",
                  paddingLeft: "10px",
                }}
              >
                <SyncLoader
                  margin={1}
                  size={10}
                  speedMultiplier={1}
                  color={survey?.survey_theme?.primaryColor}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="vw-100 vh-100" />
      )}
    </LoadingOverlay>
  );
}
// Redux mapping function to map state and dispatch to props
const mapStateToProps = (state) => {
  return {
    answeres: state.survey.answeres,
    locations: state.survey.locations,
    survey_without_login: state.survey.survey_without_login,
    survey_theme: state.survey.survey_theme,
    survey: state.survey,
    directorMessage: state.survey.directorMessage,
    contactDetails: state.contactDetails,
  };
};
// Redux mapping function to map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    addDirectorMessage_: (data) => dispatch(addDirectorMessage(data)),
    removeAnswer_: (data) => dispatch(removeAnswer(data)),
    setSurveyTheme_: (data) => dispatch(setSurveyTheme(data)),
  };
};
// Connect the Redux store to the component
export default connect(mapStateToProps, mapDispatchToProps)(Question);
